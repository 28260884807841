import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import MEDIA from 'utils/helpers/media'
import PageIntro from 'components/PageIntro/PageIntro'
import translations from 'assets/translations/translations'
import Footer from 'components/Footer/Footer'

const PageContainer = styled.div`
  margin-bottom: 50px;
  margin-top: -100px;
  ${MEDIA.MIN_TABLET`
  margin-bottom: 100px;
  margin-top: -150px;
    `}
`

const Date = styled.div`
  margin-top: 5px;
  font-size: ${(props) => props.theme.fontSizes.msmall};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  margin-bottom: 20px;
  ${MEDIA.MIN_TABLET`
  font-size: ${(props) => props.theme.fontSizes.tsmall};
    `}
`
const Header = styled.div`
  position: relative;
  font-size: ${(props) => props.theme.fontSizes.mheadregular};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  margin-bottom: 20px;
  display: inline-block;
  &:after {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    content: '';
    background-color: ${(props) => props.theme.colors.blue};
  }
  ${MEDIA.MIN_TABLET`
  font-size: ${(props) => props.theme.fontSizes.theadregular};
    `}
`
const ImageContainer = styled.div`
  max-width: 800px;
`

const Content = styled.div`
  text-align: justify;
  font-size: ${(props) => props.theme.fontSizes.mregular};
  font-weight: ${(props) => props.theme.fontWeights.light};
  text-justify: inter-word;
  & > p {
    margin: 20px 0 0 0;
  }
  ${MEDIA.MIN_TABLET`
  font-size: ${(props) => props.theme.fontSizes.tregular};
    `}
`

export default function Template({ data }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const featuredImgFluid =
    data.markdownRemark.frontmatter.featuredImage.childImageSharp.fluid
  return (
    <>
      <PageIntro sectionLineHeader={translations.pages.news} />
      <PageContainer>
        <Header>{frontmatter.title}</Header>
        <ImageContainer>
          <Img fluid={featuredImgFluid} />
        </ImageContainer>
        <Date>{frontmatter.date}</Date>
        <Content
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </PageContainer>
      <Footer />
    </>
  )
}

Template.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any.isRequired,
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date
        slug
        title
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
