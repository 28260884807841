import React from 'react'
import styled from 'styled-components'
import translations from 'assets/translations/translations'

const Icon = styled.svg`
  &:hover {
    transform: scale(1.05);
  }
`

const Phone = () => (
  <a
    aria-label="Numer telefonu do kancelarii"
    target="blank"
    href={translations.links.phone}
  >
    <Icon viewBox="0 0 20 20">
      <path
        fill="#0047BA"
        d="M19.43 14.133l-4.375-1.875a.938.938 0 00-1.094.27l-1.938 2.367A14.479 14.479 0 015.1 7.973l2.369-1.937a.935.935 0 00.27-1.094L5.863.567A.944.944 0 004.789.024L.727.962A.938.938 0 000 1.875 18.123 18.123 0 0018.125 20a.938.938 0 00.914-.727l.938-4.063a.949.949 0 00-.547-1.078z"
        data-name="Icon awesome-phone-alt"
      />
    </Icon>
  </a>
)

export default Phone
