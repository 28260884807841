import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import LinkItem from 'components/Navbar/Links/LinkItem'
import gsap from 'gsap'
import translations from 'assets/translations/translations'
import MEDIA from 'utils/helpers/media'
import PropTypes from 'prop-types'

const Container = styled.div`
  position: absolute;
  display: none;
  grid-auto-flow: column;
  right: 0;
  visibility: hidden;
  top: ${(props) => (props.isIndex ? '100px' : '0px')};
  grid-column-gap: 50px;
  font-size: ${(props) => props.theme.fontSizes.tregular};
  ${MEDIA.MIN_DESKTOP`
  display: grid;
    `};
  @media (min-height: 900px) {
    top: ${(props) => (props.isIndex ? '150px' : '0px')};
  }
`

const LinkRefContainer = styled.div`
  z-index: 3;
`

const DesktopMenu = ({ isIndex }) => {
  const containerRef = useRef(null)
  const link1Ref = useRef(null)
  const link2Ref = useRef(null)
  const link3Ref = useRef(null)
  const link4Ref = useRef(null)
  const indexPageLoaded = useSelector((state) => state.state.indexPageLoaded)
  const tl = useRef()
  const tl2 = useRef()

  useEffect(() => {
    tl2.current = gsap.timeline({ paused: true })
    tl2.current.to(containerRef.current, {
      visibility: 'visible',
    })
    tl2.current.play()
  }, [])

  useEffect(() => {
    tl.current = gsap.timeline({ paused: true })
    if (window.location.pathname === '/' && !indexPageLoaded) {
      tl.current.from(containerRef.current, {
        opacity: 0,
        duration: 2,
        ease: 'power3.inOut',
      })
      tl.current.play()
    }

    return () => {
      tl.current.kill()
    }
  }, [indexPageLoaded])

  return (
    <Container isIndex={isIndex} ref={containerRef}>
      <LinkRefContainer ref={link1Ref}>
        <LinkItem
          black
          blueUnderline
          noAnimation
          link={translations.routes.index}
        >
          {translations.pages.index}
        </LinkItem>
      </LinkRefContainer>
      <LinkRefContainer ref={link2Ref}>
        <LinkItem
          black
          blueUnderline
          noAnimation
          link={translations.routes.offer}
        >
          {translations.pages.offer}
        </LinkItem>
      </LinkRefContainer>
      {/* <LinkRefContainer ref={link3Ref}>
        <LinkItem
          black
          blueUnderline
          noAnimation
          link={translations.routes.news}
        >
          {translations.pages.news}
        </LinkItem>
      </LinkRefContainer> */}
      <LinkRefContainer ref={link4Ref}>
        <LinkItem
          black
          blueUnderline
          noAnimation
          link={translations.routes.contact}
        >
          {translations.pages.contact}
        </LinkItem>
      </LinkRefContainer>
    </Container>
  )
}

DesktopMenu.propTypes = {
  isIndex: PropTypes.bool,
}

DesktopMenu.defaultProps = {
  isIndex: false,
}

export default DesktopMenu
