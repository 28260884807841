import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import SectionLineHeader from 'components/UI/SectionLineHeader/SectionLineHeader'
import NormalText from 'components/Typography/Paragraphs/NormalText'
import MarginBottom from 'components/UI/MarginBottom/MarginBottom'
import MEDIA from 'utils/helpers/media'
import DesktopMenu from 'components/Navbar/Menu/DesktopMenu'

const PageContainer = styled.div`
  margin-top: 100px;
  display: grid;
  ${MEDIA.MIN_DESKTOP`
    margin-top: 150px;
    `}
`

const HeaderWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
  width: max-content;
  letter-spacing: -0.1px;
  margin: 50px 0 50px 0;
  ${MEDIA.MIN_TABLET`
    margin: 100px 0 100px 0;
    `}
`

const PageIntro = ({ sectionLineHeader, children, description }) => (
  <>
    <PageContainer>
      <DesktopMenu />
      <SectionLineHeader>{sectionLineHeader}</SectionLineHeader>
      <HeaderWrapper>{children}</HeaderWrapper>
      <NormalText color="black" weight={300}>
        {description}
      </NormalText>
      <MarginBottom />
    </PageContainer>
  </>
)

PageIntro.propTypes = {
  sectionLineHeader: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default PageIntro
