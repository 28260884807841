import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import MEDIA from 'utils/helpers/media'

const Container = styled.div`
  display: none;
  grid-template-rows: auto;
  grid-template-columns: auto auto;
  justify-content: start;
  align-items: center;
  grid-column-gap: 5px;
  ${MEDIA.MIN_TABLET`
    grid-column-gap: 10px;
    `}
`

const Header = styled.div`
  font-size: ${(props) => props.theme.fontSizes.msmall};
  font-weight: ${(props) => props.theme.fontWeights.bolder};
  color: ${(props) => props.theme.colors.black};
  text-transform: uppercase;
  ${MEDIA.MIN_TABLET`
    font-size: ${(props) => props.theme.fontSizes.tsmall};
    `}
`
const Line = styled.div`
  background-color: ${(props) => props.theme.colors.blue};
  height: 1px;
  width: 20px;
  ${MEDIA.MIN_TABLET`
    width: 40px;
    height: 2px;
    `}
`

const SectionHeader = ({ children, sectionLineHeaderRef }) => (
  <Container ref={sectionLineHeaderRef}>
    <Line />
    <Header>{children}</Header>
  </Container>
)

SectionHeader.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  sectionLineHeaderRef: PropTypes.any,
}

SectionHeader.defaultProps = {
  sectionLineHeaderRef: () => {},
}

export default SectionHeader
