import React from 'react'
import styled from 'styled-components'
import translations from 'assets/translations/translations'
import LinkItem from 'components/Navbar/Links/LinkItem'
import Facebook from 'components/Icons/Facebook'
// import Linkedin from 'components/Icons/LinkedIn'
import Google from 'components/Icons/Google'
import Phone from 'components/Icons/Phone'
import LogoIcon from 'components/Icons/LogoIcon'
import IconContainer from 'components/Icons/IconContainer'
import MEDIA from 'utils/helpers/media'
import MarginWrapper from 'components/UI/MarginWrapper/MarginWrapper'

const BoxContainer = styled.footer`
  background-color: ${(props) => props.theme.colors.blue};
  height: 100px;
  margin-top: -100px;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  ${MEDIA.MIN_TABLET`
   height: 150px;
   margin-top: -150px;
    `}
`

const GridContainer = styled.div`
  display: grid;
  height: 100%;
  grid-template-rows: 50% auto auto;
  align-items: center;
  justify-content: center;
`

const LinksContainer = styled.div`
  display: grid;
  align-self: end;
  grid-template-columns: auto auto auto auto;
  grid-column-gap: 10px;
  font-size: ${(props) => props.theme.fontSizes.mheadSmall};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  ${MEDIA.MIN_PHONE`
  grid-column-gap: 20px;
   font-size: ${(props) => props.theme.fontSizes.tmheadSmall};
    `}
  ${MEDIA.MIN_TABLET`
  grid-column-gap: 50px;
   font-size: ${(props) => props.theme.fontSizes.theadSmall};
    `}
  ${MEDIA.MIN_DESKTOP`
  grid-column-gap: 150px;
   font-size: ${(props) => props.theme.fontSizes.theadSmall};
    `}
`

const CreditsContainer = styled.div`
  font-size: ${(props) => props.theme.fontSizes.msmallplusbigger};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  color: ${(props) => props.theme.colors.white};
  justify-self: center;
  ${MEDIA.MIN_TABLET`
  grid-column-gap: 80px;
  font-size: ${(props) => props.theme.fontSizes.tsmallplusbigger};
    `}
`

const Line = styled.div`
  height: 1px;
  align-self: end;
  background-color: ${(props) => props.theme.colors.white};
`

const VerticalFooter = styled.div`
  width: 40px;
  height: 100px;
  background-color: ${(props) => props.theme.colors.blue};
  display: grid;
  place-items: center;
  ${MEDIA.MIN_TABLET`
   width: 60px;
  height: 150px;
    `}
  ${MEDIA.MIN_DESKTOP`
   width: 60px;
  height: 200px;
    `}
`

const TopContainer = styled.div`
  margin-bottom: 100px;
  display: grid;
  grid-auto-flow: column;
  ${MEDIA.MIN_TABLET`
   margin-bottom: 150px;
    `}
  ${MEDIA.MIN_DESKTOP`
   justify-content: space-evenly;
    `}
`

const IconsContainer = styled.div`
  display: grid;
  align-content: space-around;
  grid-auto-flow: row;
`

const IconWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: max-content auto;
  grid-column-gap: 10px;
  ${MEDIA.MIN_TABLET`
   grid-column-gap: 20px;
    `}
`

const Text = styled.div`
  font-size: ${(props) => props.theme.fontSizes.msmallplusbigger};
  font-weight: ${(props) => props.theme.fontWeights.medium};
  align-self: end;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors.blue};
  }
  ${MEDIA.MIN_TABLET`
   font-size: ${(props) => props.theme.fontSizes.tsmallplusbigger};
    `}
`

const Footer = () => (
  <MarginWrapper>
    <TopContainer>
      <VerticalFooter>
        <IconContainer
          mobileHeight="16px"
          mobileWidth="16px"
          tabletWidth="32px"
          tabletHeight="32px"
        >
          <Facebook />
        </IconContainer>
        {/* <IconContainer
          mobileHeight="16px"
          mobileWidth="16px"
          tabletWidth="32px"
          tabletHeight="32px"
        >
          <Linkedin />
        </IconContainer> */}
      </VerticalFooter>
      <IconContainer
        mobileHeight="100px"
        mobileWidth="100px"
        tabletHeight="150px"
        tabletWidth="150px"
        desktopWidth="200px"
        desktopHeight="200px"
      >
        <LogoIcon />
      </IconContainer>

      <IconsContainer>
        <IconWrapper>
          <IconContainer
            mobileWidth="20px"
            mobileHeight="20px"
            tabletHeight="30px"
            tabletWidth="30px"
          >
            <Phone />
          </IconContainer>
          <a
            aria-label="Numer telefonu do kancelarii"
            href={translations.links.phone}
          >
            <Text>{translations.phone}</Text>
          </a>
        </IconWrapper>
        <IconWrapper>
          <IconContainer
            mobileWidth="20px"
            mobileHeight="20px"
            tabletHeight="30px"
            tabletWidth="30px"
          >
            <Google />
          </IconContainer>
          <a
            aria-label="Lokalizacja siedziby firmy"
            target="blank"
            href={translations.links.google}
          >
            <Text>{translations.findUs}</Text>
          </a>
        </IconWrapper>
      </IconsContainer>
    </TopContainer>
    <BoxContainer>
      <GridContainer>
        <LinksContainer>
          <LinkItem link={translations.routes.offer}>
            {translations.pages.offer}
          </LinkItem>
          <LinkItem link={translations.routes.news}>
            {translations.pages.news}
          </LinkItem>
          <LinkItem link={translations.routes.contact}>
            {translations.pages.contact}
          </LinkItem>
          <LinkItem link={translations.routes.privacy}>
            {translations.pages.privacy}
          </LinkItem>
        </LinksContainer>
        <Line />
        <CreditsContainer>{translations.footer}</CreditsContainer>
      </GridContainer>
    </BoxContainer>
  </MarginWrapper>
)

Footer.propTypes = {}

export default Footer
