import React from 'react'
import styled from 'styled-components'

const Icon = styled.svg``

const LogoIcon = () => (
  <Icon viewBox="0 0 97 96.029">
    <g data-name="Group 111">
      <g fill="#0047BA" data-name="Component 9 – 6">
        <path
          d="M0 6.846v82.225h13.85V40.327L45.064 71.54l9.8-9.794-.009-.009 42.15-42.15V0L45.054 51.944l-31.2-31.2v-.037z"
          data-name="Path 82"
        />
        <path
          d="M90.081 89.107v-52.7l-28.2 28.043-31.575 31.583h19.588l26.338-26.346v19.42z"
          data-name="Path 83"
        />
      </g>
    </g>
  </Icon>
)

export default LogoIcon
