import React from 'react'
import styled from 'styled-components'
import translations from 'assets/translations/translations'

const Icon = styled.svg`
  &:hover {
    transform: scale(1.05);
  }
`

const Google = () => (
  <a
    aria-label="Lokalizacja siedziby firmy"
    target="blank"
    href={translations.links.google}
  >
    <Icon viewBox="0 0 20 20">
      <path
        fill="#0047BA"
        d="M20 10.237c0 5.706-3.971 9.766-9.836 9.766A10.071 10.071 0 010 10 10.071 10.071 0 0110.164 0a9.862 9.862 0 016.816 2.616l-2.767 2.621C10.594 1.798 3.865 4.379 3.865 10a6.314 6.314 0 006.3 6.315 5.473 5.473 0 005.77-4.31h-5.77V8.564h9.675a8.63 8.63 0 01.16 1.673z"
        data-name="Icon awesome-google"
      />
    </Icon>
  </a>
)

export default Google
