import React from 'react'
import styled from 'styled-components'
import MEDIA from 'utils/helpers/media'

const Container = styled.div`
  margin-bottom: 50px;
  ${MEDIA.MIN_TABLET`
    margin-bottom: 100px;
    `}
`

const MarginBottom = () => <Container />

export default MarginBottom
